
.brand-scrollbar {
  padding-right: 0.5em;
}

.table-cell-truncate-in-data-grind {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff4f1f;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eb3200;
}

.brand-color-orange {
  color: #ff4f1f;
}