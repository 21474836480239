.ignite-sidebar .Mui-selected {
    border-right: .4em solid #ff4f1f !important;
}

.ignite-sidebar .MuiButtonBase-root {
    border-right: .4em solid #f3f3f3;
    padding-top: 0;
    padding-bottom: 0;
}

.ignite-sidebar .MuiButtonBase-root .MuiButtonBase-root {
    padding-left: 1.5em !important;
}

.ignite-sidebar {
    color: black !important;
}

.ignite-sidebar a:-webkit-any-link {
    color: black !important;
}